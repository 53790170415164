import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../components/Core";

import styled from "styled-components";
import Helmet from "react-helmet";
import titleImage from "../assets/image/jpeg/map-of-australia.jpg";
import iconQuote from "../assets/image/png/quote-icon.png";
import styles from '../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
  .parcel-list li {
    list-style-type: disc;
    margin-left: 20px;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <Helmet>
                <title>HotSnail — Drop Shipping Australia</title>
                <meta name="title" content="HotSnail — Drop Shipping Australia" />
                <meta name="description" content="Send your inventory to us, and let us ship it locally" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://hotsnail.com.au/" />
                <meta property="og:title" content="HotSnail — Drop Shipping Australia" />
                <meta property="og:description" content="Send your inventory to us, and let us ship it locally" />
                <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png"></meta>

                <link rel="icon" type="image/png" href="/favicon.ico" />

                <html lang="en" />
            </Helmet>

            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Drop Shipping
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Feb 5, 2021
                  </Text>
                                    <Text>Karl Lehnert
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Australia Products" className={styles.titleImage} />
                                    </div>
                                    <div>
                                        <p>Are you an international vendor planning to or currently selling products to Australia?</p>
                                        <p>Many Australians prefer to do business with customers who have an Australian address because they believe they will receive the product sooner.</p>
                                        <p>1# Reduce shipping costs through bulk shipping + local delivery costs.</p>
                                        <p>2# Reduce shipping times and get 5-star feedback.</p>
                                        <p>3# Retain an Australian presence with an Australian address.</p>
                                        <p>Our online system enables you to send us your products in bulk, we will then individually itemise and photograph each individual item and add to your online inventory. Once you make a sale, you simply log into our system and select the item to ship, where to ship it and which service you wish to use. *API service also available. </p>
                                        <p>Returns can be shipped to our address for bulk transit back to your overseas office.</p>
                                        <p>Please contact us to discuss a tailored agreement.</p>
                                        <a href="/contact-us">Contact us now</a>
                                    </div>
                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Drop Shipping</BadgePost>
                                    <BadgePost>Warehousing</BadgePost>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
